import { ANNUITY_AMOUNT_CHANGE, ANNUITY_AMOUNT_STEP_SIZE, HEALTH_SNAPSHOT_OBJ } from "@constants";
import { Box } from "@mui/material";
import { updateProposal } from "api";
import FTDropdown from "atoms/FTDropdown";
import FTIcon from "atoms/FTIcon";
import FTKnobButton from "atoms/FTKnobButton";
import FTNumberInput from "atoms/FTNumberInput";
import FTPopover from "atoms/FTPopover";
import FTSwitch from "atoms/FTSwitch";
import { useAppDispatch, useAppSelector, useSelectedProposal } from "hooks";
import { useEffect, useState } from "react";
import { set_selected_proposal } from "redux/actions";
import {
    calculateTotalExpense,
    fetchCurrencySymbol,
    formatter,
    scrollToHeader,
    toHumanReadableDate,
    totalIncome,
} from "utils/helper";

const AnnuityDrawer = ({ setSubmitDisabled }: { setSubmitDisabled: (isSubmitDisabled: boolean) => void }) => {
    const dispatch = useAppDispatch();
    const [amountError, setAmountError] = useState<string | undefined>();
    const selectedContact = useAppSelector((state) => state.selectedContact);
    const { selectedProposal, displayedProposal, setDisplayedProposal, isMonthlyOn, annuityQuoteAmount } =
        useSelectedProposal();
    const { includeAnnuities } = displayedProposal?.customData;
    const goe4dTranslation = useAppSelector((state) => state.goe4dTranslation);
    const { allocationAnnuity: allocationAnnuityFromStore } = selectedProposal?.customData;
    const allocationAnnuity = allocationAnnuityFromStore || goe4dTranslation?.body?.allocationAnnuity;

    const setIncludeAnnuities = (includeAnnuities: boolean) => {
        let updatedProposal = JSON.parse(JSON.stringify(displayedProposal));
        updatedProposal.customData.includeAnnuities = includeAnnuities;
        setDisplayedProposal(updatedProposal);
    };

    const [selectedQuotation, setSelectedQuotation] = useState<any>(
        selectedProposal?.customData?.quotations?.find((q: any) => q?.selected === true) || []
    );

    const handleQuotationSelected = async (e: any) => {
        // update current proposal quotation to selected: true
        let _proposal = JSON.parse(JSON.stringify(selectedProposal));
        let _quotations = selectedProposal?.customData?.quotations?.map((q: any) => {
            if (q?.value === e.target?.value) {
                setSelectedQuotation(q);
                return {
                    ...q,
                    selected: true,
                };
            }
            return {
                ...q,
                selected: false,
            };
        });
        _proposal.customData.quotations = _quotations;
        try {
            // dispatch(updateGlobalLoaderState(true));
            const res = await updateProposal(_proposal, selectedProposal.contactId);
            if (res?.success) {
                // set proposal to store
                dispatch(set_selected_proposal(res?.proposal));
                // dispatch(updateGlobalLoaderState(false));
            } else new Error();
        } catch (err) {
            // console.error("Update proposal error:", err);
            // dispatch(
            //     updateErrorModalState({
            //         showErrorModal: true,
            //         error: err,
            //     })
            // );
        }
    };
    const calculatedIncomeGap =
        calculateTotalExpense(displayedProposal?.customData?.expenditures) -
            totalIncome(
                displayedProposal?.customData?.definedBenefitPensions,
                displayedProposal?.customData?.otherIncomeItems,
                Number(displayedProposal?.customData?.statePensionIncome?.value)
            ) -
            annuityQuoteAmount || 0;
    const handleIncomeAmountChange = (e: any) => {
        let val = 0;
        if (e.target.value > 0) val = e.target.value;
        if (val > 10000000)
            setAmountError(`Please enter a value less than or equal to ${formatter.format(10000000)} per annum`);
        else if (val <= 0)
            setAmountError(
                "Please enter an annuity value to proceed or remove the annuity option by adjusting the toggle below."
            );
        else setAmountError(undefined);
        let updatedProposal = JSON.parse(JSON.stringify(displayedProposal));
        updatedProposal.customData.annuityQuoteAmount = val;
        setDisplayedProposal(updatedProposal);
        // dispatch(set_selected_proposal(updatedProposal));
    };
    const handleIncomeAmountStepperChange = (changeType: string) => {
        let newAmount = annuityQuoteAmount + "";
        switch (changeType) {
            case ANNUITY_AMOUNT_CHANGE.INCREASE:
                newAmount = (((Number(annuityQuoteAmount) + ANNUITY_AMOUNT_STEP_SIZE) * 100) / 100).toFixed(2);
                break;
            case ANNUITY_AMOUNT_CHANGE.DECREASE:
                newAmount = (((Number(annuityQuoteAmount) - ANNUITY_AMOUNT_STEP_SIZE) * 100) / 100).toFixed(2);
                break;
            default:
                break;
        }
        if (Number(newAmount) < 0) {
            newAmount = "0.00";
        }
        if (Number(newAmount) > 10000000)
            setAmountError(`Please enter a value less than or equal to ${formatter.format(10000000)} per annum`);
        else setAmountError(undefined);
        let updatedProposal = JSON.parse(JSON.stringify(displayedProposal));
        updatedProposal.customData.annuityQuoteAmount = newAmount;
        setDisplayedProposal(updatedProposal);
        // dispatch(set_selected_proposal(updatedProposal));
    };
    const [animatedLinesWidth, setAnimatedLinesWidth] = useState({
        expenditurePercent: 0,
        incomePercent: 0,
        incomeGapPercent: 0,
    });
    useEffect(() => {
        const { expenditures, definedBenefitPensions, otherIncomeItems, statePensionIncome } =
            displayedProposal?.customData;
        const totalExpenditureMonthly = calculateTotalExpense(expenditures || []);
        const totalIncomeMonthly = totalIncome(
            definedBenefitPensions,
            otherIncomeItems,
            Number(statePensionIncome?.value)
        );
        const requestedMonthlyIncome = Number(annuityQuoteAmount);
        const income = Number(requestedMonthlyIncome + totalIncomeMonthly);

        if (totalExpenditureMonthly > income) {
            const incomePercent = Math.round((income / totalExpenditureMonthly) * 100);
            setAnimatedLinesWidth({
                expenditurePercent: 100,
                incomePercent,
                incomeGapPercent: 100 - incomePercent,
            });
        } else {
            setAnimatedLinesWidth({
                expenditurePercent: Math.round((totalExpenditureMonthly / income) * 100),
                incomePercent: 100,
                incomeGapPercent: 0,
            });
        }
    }, [displayedProposal, annuityQuoteAmount]);
    useEffect(() => {
        if (amountError) setSubmitDisabled(true);
        else setSubmitDisabled(false);
    }, [amountError, setSubmitDisabled]);
    return (
        <section>
            {includeAnnuities && (
                <>
                    <div className="drawer-content__title-container">
                        <div className="same-line">
                            <h5 className="drawer-content__title" onClick={scrollToHeader}>
                                {" "}
                                New Annuity Income
                            </h5>
                            <span>{isMonthlyOn ? "(Monthly)" : "(Annual)"}</span>
                        </div>
                        <h5 className="drawer-content__totalAmount">{formatter.format(annuityQuoteAmount)}</h5>
                    </div>
                    <div className="annuity-drawer-text">
                        <p>How was this value for annuity calculated? </p>
                        <p> Your client has received a quotation. </p>
                    </div>
                    <article className="same-line guaranteed-income-popover">
                        <h6 className="demi-bold"> LEVEL OF GUARANTEED INCOME </h6>
                        <span onClick={scrollToHeader}>
                            <FTPopover
                                type="secondary"
                                message="To set algorithm to optimum value, do not adjust level of guaranteed income"
                            />
                        </span>
                    </article>
                    <div className="flex-split">
                        <div className="annuity-income-input">
                            <Box sx={{ flexDirection: "column", justifyContent: "space-around" }}>
                                <FTNumberInput
                                    label={`Amount ${fetchCurrencySymbol}`}
                                    data-testid={`income_amount_value`}
                                    value={annuityQuoteAmount}
                                    onChange={handleIncomeAmountChange}
                                    showErrorOutline={amountError}
                                />
                                {amountError && <p className="MuiFormHelperText-root">{amountError}</p>}
                            </Box>
                            <div className="plus-minus-btns demi-bold">
                                <FTKnobButton
                                    onClick={() => handleIncomeAmountStepperChange(ANNUITY_AMOUNT_CHANGE.INCREASE)}
                                >
                                    +
                                </FTKnobButton>
                                <FTKnobButton
                                    onClick={() => handleIncomeAmountStepperChange(ANNUITY_AMOUNT_CHANGE.DECREASE)}
                                >
                                    -
                                </FTKnobButton>
                            </div>
                        </div>
                        <div className="income-gap" onClick={scrollToHeader}>
                            <div className="animated-arrow">
                                <FTIcon iconName="ft-arrow" />
                            </div>
                            <div className="animated-text">
                                <div className="animated-text__title"> THE INCOME GAP </div>
                                <div data-testid="animated-text__amount" className="long-text-wrap-ellipsis">
                                    {formatter.format(calculatedIncomeGap)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="expenditure-title" onClick={scrollToHeader}>
                        <FTIcon iconName="ft-drawerExpenditure" /> Expenditure
                    </div>
                    <div className="animated-expenditure-line">
                        <div
                            style={
                                {
                                    "--series-width": `${animatedLinesWidth?.expenditurePercent}%`,
                                } as React.CSSProperties
                            }
                        >
                            <div className="expenditure-line"></div>
                        </div>
                        <div className="income-title" onClick={scrollToHeader}>
                            <FTIcon iconName="ft-drawerBenefitsIncome" /> Income
                        </div>
                    </div>
                    <div className="animated-income-line">
                        <div
                            style={
                                {
                                    "--series-width": `${animatedLinesWidth?.incomePercent}%`,
                                } as React.CSSProperties
                            }
                        >
                            <div className="income-line"></div>
                        </div>
                        <div
                            style={
                                {
                                    "--series-width": `${animatedLinesWidth?.incomeGapPercent}%`,
                                } as React.CSSProperties
                            }
                        >
                            <div className="income-gap"></div>
                        </div>
                    </div>
                    <br />
                    <hr className="drawer-section-seperator" />
                    {selectedProposal?.customData?.quotations?.length > 0 ? (
                        <div className="drawer-section-paragraph">
                            <h6 className="demi-bold"> PERSONALISED QUOTATION </h6>
                            <FTDropdown
                                id="quotation-dropdown"
                                data-testid="quotation-dropdown"
                                label="Quotation"
                                type="text"
                                noDataMessage=""
                                options={
                                    selectedProposal?.customData?.quotations?.map((q: any) => ({
                                        ...q,
                                        label: q.value,
                                    })) || []
                                }
                                onChange={handleQuotationSelected}
                                placeholder="Quotation"
                                value={selectedQuotation?.value || ""}
                            />
                            <div className="quotation-info">
                                <div className="launchpad-key-value-line">
                                    <span>Annuity Income Value</span>
                                    <span>
                                        {selectedQuotation?.incomeValue
                                            ? formatter.format(selectedQuotation?.incomeValue)
                                            : ""}
                                    </span>
                                </div>
                                <div className="launchpad-key-value-line">
                                    <span>Annuity Value</span>
                                    <span>
                                        {selectedQuotation?.premiumValue
                                            ? formatter.format(selectedQuotation?.premiumValue)
                                            : ""}
                                    </span>
                                </div>
                                <div className="launchpad-key-value-line">
                                    <span>Percentage</span>
                                    <span>
                                        {selectedQuotation?.percentage ? selectedQuotation?.percentage * 100 + "%" : ""}
                                    </span>
                                </div>
                                <div className="key-value-line">
                                    <span>Expiry Date</span>
                                    <span className="align-right demi-bold">
                                        {selectedQuotation?.expiryDate
                                            ? toHumanReadableDate(selectedQuotation?.expiryDate)
                                            : ""}
                                    </span>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="drawer-section-paragraph">
                            <h6 className="demi-bold"> SLI INDICATIVE QUOTE </h6>
                            <p>
                                For an annuity income of{" "}
                                <b>
                                    <span className="demi-bold">
                                        {`${formatter.format(annuityQuoteAmount)} ${
                                            isMonthlyOn ? "(Monthly) " : "(Annual) "
                                        }`}
                                    </span>
                                </b>
                                the purchase annuity value is{" "}
                                <span className="demi-bold">{`${formatter.format(allocationAnnuity)} (Annual)`}</span>.
                            </p>
                        </div>
                    )}
                    <hr className="drawer-section-seperator" />
                    <div className="drawer-section-paragraph">
                        <h6 className="demi-bold">HEALTH PERSONA</h6>
                        <div className="health-persona">
                            <div onClick={scrollToHeader}>
                                <FTIcon iconName={HEALTH_SNAPSHOT_OBJ[selectedContact?.healthStatus].iconName} />
                            </div>
                            <div>
                                <h6 className="demi-bold">
                                    {HEALTH_SNAPSHOT_OBJ[selectedContact?.healthStatus].title}
                                </h6>
                                <p>{HEALTH_SNAPSHOT_OBJ[selectedContact?.healthStatus].content}</p>
                            </div>
                        </div>
                    </div>
                </>
            )}
            <h6 className="demi-bold">INCLUDE / EXCLUDE ANNUITIES</h6>
            <span className="include-annuities-switch">
                <FTSwitch
                    label="Include Annuities"
                    switchName="Include Annuities"
                    aria-label="Include Annuities"
                    checked={includeAnnuities}
                    data-testid="include-annuities"
                    onChange={(val) => {
                        // console.log(val)
                        setIncludeAnnuities(val);
                        if (!val) {
                            setSubmitDisabled(false);
                        }
                    }}
                />
            </span>
        </section>
    );
};

export default AnnuityDrawer;
