import { drawersSteps } from "@constants/drawers-constants";
import FTButton from "atoms/FTButton";
import FTIcon from "atoms/FTIcon";
import { useAppSelector, useGoe4d, useSelectedProposal, useSpire } from "hooks";
import MonthlyAnnualToggle from "pages/FactFinding/MonthlyAnnualToggle";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import {
    calculateSumOfAllOtherWealthValues,
    calculateTotalExpense,
    calculateTotalInvestmentValue,
    formatter,
    scrollToHeader,
    totalIncome,
} from "utils/helper";
import { hasValue } from "utils/validateInputs";
import "../styles/drawers.styles.scss";
import AnnuityDrawer from "./AnnuityDrawer";
import CashDrawer from "./CashDrawer";
import ExpenditureDrawer from "./ExpenditureDrawer";
import IncomeDrawer from "./IncomeDrawer";
import RiskDrawer from "./RiskDrawer";
import WealthDrawer from "./WealthDrawer";

const StepDrawers = ({ drawerActions, setDrawerActions }: { drawerActions: any; setDrawerActions: any }) => {
    const location = useLocation();
    const bloomreachCDN = useAppSelector((state) => state?.bloomreachCDN);
    const cashReservesRate = useAppSelector((state) => state?.global?.orgInfo?.settings?.cashReservesRate);
    const displayedProposal = useAppSelector((state) => state.displayedProposal);
    const selectedProposal = useAppSelector((state) => state.selectedProposal);

    const {
        includeAnnuities,
        riskScore,
        definedBenefitPensions,
        expenditures,
        otherIncomeItems,
        statePensionIncome,
        wealthItems,
        feeRate,
        cashPotAmount,
    } = displayedProposal?.customData || selectedProposal?.customData;
    const { persistProposalToAPI, annualDisplayedProposal } = useSelectedProposal();
    // const goe4dTranslation = useAppSelector((state) => state.goe4dTranslation);
    // const additionalPayoutAnnuity = goe4dTranslation?.body?.additionalPayoutAnnuity;
    const { annuityQuoteAmount } = useAppSelector((state) => state?.selectedProposal?.customData);
    console.log("annuityQuoteAmount", annuityQuoteAmount);
    const [isSubmitDisabled, setSubmitDisabled] = useState(false);
    const statePensionAmount = Number(statePensionIncome?.value);
    const {
        viewProposalDisclaimers: {
            wealthDrawer,
            incomeDrawer,
            expenditureDrawer,
            riskDrawer,
            annuitiesDrawer,
            cashDrawer,
        },
    } = bloomreachCDN;

    const { fetchSpireData } = useSpire();
    const { fetchGoe4d } = useGoe4d();
    const { setDisplayedProposal } = useSelectedProposal();
    const feeStructure = useAppSelector((state) => state?.global?.orgInfo?.feeStructure);

    /**	Drawer Label Starts	 */
    const totalWealth = calculateSumOfAllOtherWealthValues(wealthItems);
    const totalIncomeYearly = useMemo(
        () => Number(totalIncome(definedBenefitPensions, otherIncomeItems, statePensionAmount)) || 0,
        [definedBenefitPensions, otherIncomeItems, statePensionAmount]
    );
    const totalExpenditureYearly = useMemo(() => Number(calculateTotalExpense(expenditures)) || 0, [expenditures]);
    const totalAnnuities = includeAnnuities ? formatter.format(annuityQuoteAmount || 0) : "-";

    const [feeCharge, setFeeCharge] = useState<{ value: string | number; error: string }>({
        value: feeRate * 100,
        error: "",
    });
    const [otherCashReserve, setOtherCashReserve] = useState<{ value: string | number; error: string }>({
        value: cashPotAmount,
        error: "",
    });

    const [cashValue, setCashValue] = useState(0);
    const totalCashReserves = cashValue + +otherCashReserve?.value;

    useEffect(() => {
        if (feeRate === null) {
            for (let i = 0; i < feeStructure.length; i++) {
                if (totalWealth < feeStructure[i].to && totalWealth > feeStructure[i].from) {
                    setFeeCharge({ error: "", value: (feeStructure[i].fee * 100)?.toFixed(2) });
                }
            }
        }
    }, [totalWealth, feeStructure, feeRate, cashValue]);

    useEffect(() => {
        setCashValue((totalWealth * +feeCharge.value) / 100);
    }, [feeCharge, totalWealth]);

    const handleFeeCharge = (value: string) => {
        setFeeCharge({ error: "", value: value || 0 });
        Number(value) > 100
            ? setFeeCharge({ error: "Please enter a value less than 100%", value: parseFloat(value) })
            : "";

        let updatedProposal = JSON.parse(JSON.stringify(displayedProposal));
        updatedProposal.customData.feeRate = ~~Number(feeCharge.value) / 100;
        setDisplayedProposal(updatedProposal);
    };

    useEffect(() => {
        if (cashPotAmount === null) {
            setOtherCashReserve({ value: (totalWealth * cashReservesRate).toFixed(2), error: "" });
        }
    }, [cashPotAmount, otherCashReserve.value, cashReservesRate, totalWealth]);

    const handleOtherCashReserve = (value: string) => {
        setOtherCashReserve({ error: "", value: value || 0 });
        if (Number(value) > calculateTotalInvestmentValue(wealthItems)) {
            setOtherCashReserve({
                error: "The cash reserves value cannot exceed total wealth",
                value: value,
            });
        }
        let updatedProposal = JSON.parse(JSON.stringify(displayedProposal));
        updatedProposal.customData.cashPotAmount = +otherCashReserve.value;
        setDisplayedProposal(updatedProposal);
    };

    useEffect(() => {
        // disableClosingDrawer
        if (hasValue(feeCharge.error) || hasValue(otherCashReserve.error)) {
            setSubmitDisabled(true);
        } else {
            setSubmitDisabled(false);
        }
    }, [feeCharge.error, otherCashReserve.error, setSubmitDisabled]);

    /**	Drawer Label Ends	 */
    const openSelectedStepDrawer = (drawer: any) => {
        if (!isSubmitDisabled) {
            scrollToHeader();
            setDrawerActions({
                selectedDrawer: drawer,
                isDrawerVisible: true,
                disabledDrawer: "",
            });
        }
    };

    const handleCloseAndUpdateDrawer = async () => {
        // Don't call APIs if nothing changed
        const somethingChanged = JSON.stringify(selectedProposal) !== JSON.stringify(annualDisplayedProposal);
        if (!isSubmitDisabled && somethingChanged) {
            await persistProposalToAPI(drawerActions.selectedDrawer, {
                ...displayedProposal?.customData?.meta,
            });
            if (
                drawerActions.selectedDrawer === "annuity" &&
                // || drawerActions.selectedDrawer === "wealth")
                includeAnnuities
            ) {
                fetchSpireData();
            } else {
                fetchGoe4d(true); //futureAnnuityProj: true
                // fetchGoe4d(false); //futureAnnuityProj: true
            }
        }
        setDrawerActions({
            selectedDrawer: "",
            isDrawerVisible: false,
            disabledDrawer: "",
        });
    };

    useEffect(() => {
        if (location?.search?.includes("annuity")) {
            setDrawerActions({
                selectedDrawer: "annuity",
                isDrawerVisible: true,
                disabledDrawer: "",
            });
        }
    }, [location?.search, setDrawerActions]);

    const drawerMapper: any = {
        wealth: {
            component: <WealthDrawer isSubmitDisabled={isSubmitDisabled} setSubmitDisabled={setSubmitDisabled} />,
            disclaimerContent: wealthDrawer,
            subTitle: formatter.format(totalWealth),
        },
        benefitsIncome: {
            component: (
                <IncomeDrawer
                    isSubmitDisabled={isSubmitDisabled}
                    setSubmitDisabled={setSubmitDisabled}
                    totalIncome={totalIncomeYearly}
                />
            ),
            disclaimerContent: incomeDrawer,
            subTitle: formatter.format(totalIncomeYearly),
        },
        expenditure: {
            component: (
                <ExpenditureDrawer setSubmitDisabled={setSubmitDisabled} totalExpense={totalExpenditureYearly} />
            ),
            disclaimerContent: expenditureDrawer,
            subTitle: formatter.format(totalExpenditureYearly),
        },
        risk: {
            component: <RiskDrawer />,
            disclaimerContent: riskDrawer,
            subTitle: riskScore,
        },
        annuity: {
            component: <AnnuityDrawer setSubmitDisabled={setSubmitDisabled} />,
            disclaimerContent: annuitiesDrawer,
            subTitle: totalAnnuities,
        },
        cashReserves: {
            component: (
                <CashDrawer
                    isSubmitDisabled={isSubmitDisabled}
                    handleFeeCharge={handleFeeCharge}
                    handleOtherCashReserve={handleOtherCashReserve}
                    feeCharge={feeCharge}
                    otherCashReserve={otherCashReserve}
                    cashValue={cashValue}
                    totalCashReserves={totalCashReserves}
                />
            ),
            disclaimerContent: cashDrawer,
            subTitle: formatter.format(totalCashReserves || 0),
        },
    };

    return (
        <section className="drawer-container">
            <MonthlyAnnualToggle />

            <div className="drawer">
                {drawersSteps?.map((drawer: any, index: number) => (
                    <div
                        key={index}
                        className={isSubmitDisabled ? "disabled-cursor" : ""}
                        data-testid={`drawer-${drawer?.slug}`}
                    >
                        <div
                            className={`drawer__btn-container ${
                                (drawer.slug === drawerActions.selectedDrawer ? "selected" : "") ||
                                (drawer.slug === drawerActions?.disabledDrawer ? "inactive" : "")
                            }`}
                            tabIndex={0}
                            onClick={() => openSelectedStepDrawer(drawer.slug)}
                        >
                            <FTIcon iconName={drawer.iconClass} className="drawer__icon floating" />

                            <div className="drawer__title-container">
                                <div className="drawer__title">{drawer.title}</div>
                                <div className="drawer__sub-title">
                                    {drawerMapper[drawer.slug]?.subTitle && drawerMapper[drawer.slug]?.subTitle}
                                </div>
                            </div>
                            <FTIcon iconName="ft-edit" className="drawer__edit-icon" icon="edit" />
                        </div>
                    </div>
                ))}

                {drawerActions?.isDrawerVisible && (
                    <div className="expanded-drawer">
                        <FTButton
                            variant="text"
                            startIcon={<FTIcon iconName="ft-cross" />}
                            data-testid="close-drawer-btn"
                            onClick={handleCloseAndUpdateDrawer}
                            className={`ft-button__button left-aligned-btn ${
                                isSubmitDisabled ? "disabled-cursor low-opacity" : ""
                            }`}
                        >
                            Close and Update
                        </FTButton>

                        {drawerActions?.isDrawerVisible && drawerMapper[drawerActions.selectedDrawer]?.component}
                        <br />
                        <div className="drawer-content__disclaimers">
                            {drawerActions.isDrawerVisible &&
                                drawerMapper[drawerActions.selectedDrawer]?.disclaimerContent}
                        </div>
                    </div>
                )}
            </div>
        </section>
    );
};

export default StepDrawers;
