// import { goe4dTranslation } from "@constants/mock-data";
import { Box } from "@mui/material";
import FTPopover from "atoms/FTPopover";
import { useAppSelector } from "hooks";
import { calculateCurrentAge, formatter } from "utils/helper";
import { AmountAtAge } from "../PortfolioTab/portfolio-mapper-helpers";
import FTBarChart from "./FTBarChart";
import { useEffect } from "react";

const AnnuitiesTab = () => {
    const { birthdate, healthStatus } = useAppSelector((state) => state?.selectedContact);
    const goe4dTranslation = useAppSelector((state) => state.goe4dTranslation);
    const lifeSpan = useAppSelector((state) => state?.global.orgInfo?.settings?.lifeSpan);
    const { healthPersonaPercentage } = useAppSelector((state) => state?.global.orgInfo?.settings);
    const { annuityQuoteAmount, includeAnnuities } = useAppSelector((state) => state?.selectedProposal.customData);
    const { goodHealth, nonSmoker, smoker } = healthPersonaPercentage;
    const { viewProposalGraphDescription, viewProposalDisclaimers } = useAppSelector((state) => state.bloomreachCDN);

    const { projectedGuaranteedIncome } = goe4dTranslation;
    const futureAnnuityProjections = goe4dTranslation?.body?.futureAnnuityProjections;
    const allocationAnnuity = goe4dTranslation?.body?.allocationAnnuity;
    const futureAnnuityProjectionsValue = allocationAnnuity === 0 ? 0 : futureAnnuityProjections || [];
    const { recommendedAnnuitiesGraphDescription, projectedGIDescription } = viewProposalGraphDescription || {};
    const { recommendedAnnuitiesGraphDisclaimer, annuitiesTabDisclaimer } = viewProposalDisclaimers || [];
    const annualDisplayedProposal = useAppSelector((state) => state.annualDisplayedProposal);
    const { wealthItems } = annualDisplayedProposal?.customData;

    const calculateAnnuityQuotePercent = () => {
        if (healthStatus === "AboveAverage") {
            return (goodHealth * 100).toFixed(2);
        } else if (healthStatus === "Average") {
            return (nonSmoker * 100).toFixed(2);
        } else if (healthStatus === "BelowAverage") {
            return (smoker * 100).toFixed(2);
        }
    };

    const annuityRate = allocationAnnuity === 0 ? 0 : calculateAnnuityQuotePercent();
    const proposedAnnuityAmount = allocationAnnuity; // AEX-745 new flow
    const annuityQuotePercent = annuityRate;
    const proposedAnnuityIncreaseAmount = proposedAnnuityAmount;
    const goe4DAnnuitazationData = futureAnnuityProjectionsValue || [];

    useEffect(() => {
        calculateAnnuityQuotePercent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allocationAnnuity, annuityQuoteAmount, wealthItems, includeAnnuities]);

    let currentAge = calculateCurrentAge(birthdate);
    const ageArray: any = [];
    for (let age = currentAge; age <= lifeSpan; age++) {
        ageArray.push(age);
    }

    const calculateGuaranteedIncomeData = (ageArray: Array<number>, projectedGuaranteedIncome: Array<number>) => {
        let guaranteedIncomeData: AmountAtAge[] = projectedGuaranteedIncome
            ? ageArray?.map((id: number, index: number) => ({
                  age: id,
                  amount: projectedGuaranteedIncome[index] || 0,
              }))
            : [];
        return guaranteedIncomeData;
    };

    let annuitizationData: any = [proposedAnnuityIncreaseAmount, ...goe4DAnnuitazationData];

    const calculateAnnuitizationData = (ageArray: Array<number>, annualRecommAnnuitization: []) => {
        let annuitizationData: AmountAtAge[] = ageArray?.map((id: number, index: number) => ({
            age: id,
            amount: annualRecommAnnuitization[index],
        }));
        return annuitizationData;
    };

    const renderAnnuityQuote = (annuityQuotePercent: any) => {
        return (
            <div className="qf-label-value">
                <div className="qf-label-value__label">Annuity quote</div>
                <div className="qf-label-value__value">{!includeAnnuities ? "0%" : `${annuityQuotePercent}%`}</div>
            </div>
        );
    };

    const renderProposedAnnuityIncrease = (proposedAnnuityIncreaseAmount: any) => {
        return (
            <div className="qf-label-value">
                <div className="qf-label-value__label">Proposed increase in annuity</div>
                <div className="qf-label-value__value">
                    {!includeAnnuities ? formatter.format(0) : formatter.format(proposedAnnuityIncreaseAmount)}
                </div>
            </div>
        );
    };

    return (
        <>
            <h5>Annual Recommended Annuitisation</h5>
            <div className="chart-content-container">
                <div className="chart-content__content">
                    <div data-testid="annuity-info__annuity_quote">{renderAnnuityQuote(annuityQuotePercent)}</div>
                    <div data-testid="annuity-info__proposed_increase">
                        {renderProposedAnnuityIncrease(proposedAnnuityIncreaseAmount)}
                    </div>
                </div>
                <div className="chart-content__description">
                    <p className="disclaimer">
                        {recommendedAnnuitiesGraphDescription && recommendedAnnuitiesGraphDescription}
                    </p>
                </div>
            </div>

            <br />
            <FTBarChart
                gradientColors="['rgba(4, 144, 200, 1)', 'rgba(4, 144, 200, 0.58)']"
                data={calculateAnnuitizationData(ageArray, annuitizationData)}
            />

            <br />
            <p className="disclaimer">
                {recommendedAnnuitiesGraphDisclaimer &&
                    recommendedAnnuitiesGraphDisclaimer?.map((item, i) => <p key={i}>{item}</p>)}
            </p>

            <br />

            <div className="chart-content-container">
                <div className="chart-content__content">
                    <Box sx={{ display: "flex", alignItems: "baseline" }}>
                        <h5> Projected Guaranteed Income </h5>
                        <FTPopover
                            type="secondary"
                            message="The below graph shows what could occur if the above annuity is executed alongside a model portfolio. It also adds your client's existing sources of guaranteed income to present the maximum potential secure income"
                        />
                    </Box>
                </div>
                <div className="chart-content__description">
                    <p className="disclaimer">{projectedGIDescription && projectedGIDescription}</p>
                </div>
            </div>

            <br />

            <FTBarChart
                gradientColors="['rgba(255, 168, 22, 1)', 'rgba(255, 168, 22, 0.4)']"
                data={calculateGuaranteedIncomeData(ageArray, projectedGuaranteedIncome)}
            />

            <p className="disclaimer">{annuitiesTabDisclaimer && annuitiesTabDisclaimer}</p>
        </>
    );
};

export default AnnuitiesTab;
