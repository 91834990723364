import { toRelativeUrl } from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";
import { fetchBloomreachDisclaimerContent, fetchBloomreachGeneralContent, fetchBloomreachFaqData } from "api";
import FTErrorModal from "atoms/FTErrorModal";
import FTLogoutModal from "atoms/FTLogoutModal";
import { logout } from "auth/authSlice";
import oktaAuth from "auth/oktaAuth";
import { useAppDispatch, useAppSelector, useSelectedProposal } from "hooks";
import { useCallback, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
    add_content,
    set_annual_displayed_proposal,
    set_displayed_proposal,
    set_goe4d_data,
    set_selected_contact,
    set_selected_proposal,
    set_spire_data,
    set_spire_portal_url,
    set_view_loading,
} from "redux/actions";
import { hasValue } from "utils/validateInputs";
import HeaderView from "../components/Header";
import FTExternalLinkModal from "atoms/FTExternalLinkModal";
import FTFaqModal from "components/Support/FTFaqModal";
import FTContactUsModal from "components/Support/FTContactUsModal";
import FTResourcesModal from "components/Support/FTResourcesModal";
import { scrollToTop } from "utils/helper";
// import FTGlobalLoader from "atoms/FTGlobalLoader";

const Header = (props: {}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { showErrorModal } = useAppSelector((state) => state.global);
    const showExternalLinkModal = useAppSelector((state) => state.showExternalLinkModal);
    const [showLogoutModal, setShowLogoutModal] = useState(false);
    const [showFAQModal, setShowFAQModal] = useState(false);
    const [showContactUsModal, setShowContactUsModal] = useState(false);
    const [showResourcesModal, setShowResourcesModal] = useState(false);
    const selectedContact = useAppSelector((state) => state.selectedContact);
    // const viewProposalLoading = useAppSelector((state) => state.viewProposalLoading);
    const { selectedProposal } = useSelectedProposal();
    const [showClientAndProposalInfo, setShowClientAndProposalInfo] = useState<
        { contactName: string; proposalName?: string } | undefined
    >();

    const handleFaqClose = () => {
        setShowFAQModal(false);
    };

    const customAuthHandler = () => {
        navigate("/");
    };
    const restoreOriginalUri = async (_oktaAuth: any, originalUri: string) => {
        navigate(toRelativeUrl(originalUri || "", window.location.origin), {
            replace: true,
        });
    };

    const fetchCDNContent = useCallback(async () => {
        const fetchedContent = await fetchBloomreachDisclaimerContent();
        dispatch(add_content(fetchedContent));
    }, [dispatch]);

    const fetchGeneralContentBloomreach = useCallback(async () => {
        const fetchedStateContent = await fetchBloomreachGeneralContent();
        dispatch(add_content(fetchedStateContent));
    }, [dispatch]);

    const fetchFaqDataBloomreach = useCallback(async () => {
        const fetchedFaqData = await fetchBloomreachFaqData();
        dispatch(add_content(fetchedFaqData));
    }, [dispatch]);

    const isOnLanding = () => location.pathname === "/advisor/landing";
    const routeToLanding = () => {
        dispatch(set_selected_contact({}));
        dispatch(set_selected_proposal({}));
        dispatch(set_displayed_proposal({}));
        dispatch(set_annual_displayed_proposal({}));
        dispatch(set_spire_data({}));
        dispatch(set_goe4d_data({}));
        dispatch(set_spire_portal_url(""));
        navigate("/advisor/landing");
    };
    const handleLogout = () => {
        dispatch(set_view_loading(true));
        dispatch(set_selected_contact({}));
        dispatch(set_selected_proposal({}));
        dispatch(set_displayed_proposal({}));
        dispatch(set_annual_displayed_proposal({}));
        dispatch(set_spire_data({}));
        dispatch(set_goe4d_data({}));
        dispatch(set_spire_portal_url(""));
        dispatch(logout());
        oktaAuth.tokenManager.clear();
        setShowLogoutModal(false);
        navigate("/?reason=logoutSuccess");
        dispatch(set_view_loading(false));
    };

    useEffect(() => {
        if (
            location.pathname.includes("fact-finding") ||
            location.pathname.includes("view-proposal") ||
            location.pathname.includes("next-steps")
        ) {
            setShowClientAndProposalInfo({
                contactName: `${selectedContact.firstName} ${selectedContact.lastName}`,
                proposalName: selectedProposal.name,
            });
        } else if (location.pathname.includes("proposals")) {
            setShowClientAndProposalInfo({ contactName: selectedContact.label, proposalName: "" });
        } else setShowClientAndProposalInfo(undefined);
    }, [location.pathname, selectedContact, selectedProposal]);

    useEffect(() => {
        fetchCDNContent();
        fetchGeneralContentBloomreach();
        fetchFaqDataBloomreach();
    }, [fetchCDNContent, fetchGeneralContentBloomreach, fetchFaqDataBloomreach]);

    useEffect(() => {
        scrollToTop();
    }, [location.pathname]);

    return (
        <Security oktaAuth={oktaAuth} onAuthRequired={customAuthHandler} restoreOriginalUri={restoreOriginalUri}>
            <HeaderView
                {...props}
                handleLogout={() => setShowLogoutModal(true)}
                handleFaq={() => setShowFAQModal(true)}
                handleContactUs={() => setShowContactUsModal(true)}
                handleResources={() => setShowResourcesModal(true)}
                isOnLanding={isOnLanding}
                routeToLanding={routeToLanding}
                showClientAndProposalInfo={showClientAndProposalInfo}
            />
            <Outlet />
            {showErrorModal && <FTErrorModal />}
            {showLogoutModal && (
                <FTLogoutModal
                    showLogoutModal={showLogoutModal}
                    setShowLogoutModal={setShowLogoutModal}
                    handleLogout={handleLogout}
                />
            )}
            {showFAQModal && <FTFaqModal showFAQModal={showFAQModal} handleFaqClose={handleFaqClose} />}
            {showContactUsModal && (
                <FTContactUsModal
                    showContactUsModal={showContactUsModal}
                    setShowContactUsModal={setShowContactUsModal}
                />
            )}
            {showResourcesModal && (
                <FTResourcesModal
                    showResourcesModal={showResourcesModal}
                    setShowResourcesModal={setShowResourcesModal}
                />
            )}
            {hasValue(showExternalLinkModal) && <FTExternalLinkModal />}
            {/* <FTGlobalLoader open={viewProposalLoading} /> */}
        </Security>
    );
    // view code at components/Header/index.tsx
};

export default Header;
