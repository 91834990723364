import { StepRoute } from "@types";
import { updateContact, updateProposal } from "api";
import { useEffect } from "react";
import { getAnnualValue, getMonthlyValue } from "utils/helper";
import { useAppDispatch, useAppSelector } from ".";
import {
    set_annual_displayed_proposal,
    set_displayed_proposal,
    // set_proposals,
    set_selected_contact,
    set_selected_proposal,
} from "../redux/actions";
import { Proposal } from "../redux/models";

export const useSelectedProposal = () => {
    const dispatch = useAppDispatch();
    const selectedContact = useAppSelector((state) => state.selectedContact);
    const selectedProposal = useAppSelector((state) => state.selectedProposal);
    const displayedProposal = useAppSelector((state) => state.displayedProposal);

    const setDisplayedProposal = (updatedProposal: Proposal) => {
        dispatch(set_displayed_proposal(updatedProposal));
    };
    const annualDisplayedProposal = useAppSelector((state) => state.annualDisplayedProposal);
    const setAnnualDisplayedProposal = (updatedProposal: Proposal) => {
        dispatch(set_annual_displayed_proposal(updatedProposal));
    };
    const adjustValuesIfIsMonthlyOn = (isMonthlyOn: boolean, selectedProposalArg?: Proposal) => {
        let updatedProposal = JSON.parse(JSON.stringify(selectedProposalArg));
        // update isMonthlyOn
        updatedProposal.customData.isMonthlyOn = isMonthlyOn;
        if (isMonthlyOn) {
            // console.log("isMonthlyOn", isMonthlyOn);
            // update statePensionIncome
            updatedProposal.customData.statePensionIncome.value = getMonthlyValue(
                selectedProposalArg?.customData?.statePensionIncome?.value
            );
            // update expenditures
            let updatedExpenditures = Object.entries(selectedProposalArg?.customData?.expenditures || {});
            updatedExpenditures = updatedExpenditures?.reduce(
                (exp, [item, val]) => ({
                    ...exp,
                    [item]: getMonthlyValue(Number(val)),
                }),
                selectedProposalArg?.customData?.expenditures
            );
            updatedProposal.customData.expenditures = updatedExpenditures;
            // update otherIncomeItems
            updatedProposal.customData.otherIncomeItems = selectedProposalArg?.customData?.otherIncomeItems?.map(
                (item: any) => ({
                    ...item,
                    value: getMonthlyValue(item?.value),
                })
            );
            // update definedBenefitPensions
            updatedProposal.customData.definedBenefitPensions =
                selectedProposalArg?.customData?.definedBenefitPensions?.map((item: any) => {
                    // console.log("u", getMonthlyValue(item?.value))
                    return {
                        ...item,
                        value: getMonthlyValue(item?.value),
                    };
                });
            // update annuityQuoteAmount for SPIRE
            updatedProposal.customData.annuityQuoteAmount = getMonthlyValue(
                selectedProposalArg?.customData?.annuityQuoteAmount
            );
            // return updatedProposal;
        }
        return updatedProposal;
    };
    const precalculateProposalInAnnual = (updatedProposal: Proposal) => {
        let updatedProposalInAnnual = JSON.parse(JSON.stringify(updatedProposal));
        // update isMonthlyOn
        updatedProposalInAnnual.customData.isMonthlyOn = displayedProposal?.customData?.isMonthlyOn;
        // update statePensionIncome
        updatedProposalInAnnual.customData.statePensionIncome.value = getAnnualValue(
            updatedProposal?.customData?.statePensionIncome?.value
        );
        // update expenditures
        let updatedExpenditures = Object.entries(updatedProposal?.customData?.expenditures || {});
        updatedExpenditures = updatedExpenditures?.reduce((exp, [item, val]) => {
            return {
                ...exp,
                [item]: getAnnualValue(Number(val)),
            };
        }, updatedProposal?.customData?.expenditures);
        updatedProposalInAnnual.customData.expenditures = updatedExpenditures;
        // update otherIncomeItems
        updatedProposalInAnnual.customData.otherIncomeItems = updatedProposal?.customData?.otherIncomeItems?.map(
            (item: any) => ({
                ...item,
                value: getAnnualValue(item?.value),
            })
        );
        // update definedBenefitPensions
        updatedProposalInAnnual.customData.definedBenefitPensions =
            updatedProposal?.customData?.definedBenefitPensions?.map((item: any) => ({
                ...item,
                value: getAnnualValue(item?.value),
            }));
        // update annuityQuoteAmount for SPIRE
        updatedProposalInAnnual.customData.annuityQuoteAmount = Math.ceil(
            getAnnualValue(updatedProposal?.customData?.annuityQuoteAmount)
        ).toFixed(2);
        setAnnualDisplayedProposal(updatedProposalInAnnual);
    };
    // const { isMonthlyOn } = displayedProposal?.customData || {};
    const setBothDisplayedProposals = (updatedProposal: Proposal) => {
        if (annualDisplayedProposal?.customData?.isMonthlyOn) {
            setDisplayedProposal(updatedProposal);
            precalculateProposalInAnnual(updatedProposal);
        } else {
            setDisplayedProposal(updatedProposal);
            setAnnualDisplayedProposal(updatedProposal);
        }
    };

    const persistProposalToAPI = async (onStep: string, meta: any) => {
        // updatecontact with wealthItems
        if (onStep === StepRoute.wealth) {
            try {
                // dispatch(updateGlobalLoaderState(true));
                const response = await updateContact({
                    ...selectedContact,
                    wealthItems: [...displayedProposal?.customData?.wealthItems],
                });
                // dispatch(updateGlobalLoaderState(false));
                if (response?.success) {
                    dispatch(set_selected_contact(await response?.contact));
                } else new Error();
            } catch (err) {
                console.error("Update client error:", err);
                // dispatch(
                //     updateErrorModalState({
                //         showErrorModal: true,
                //         error: err,
                //     })
                // );
            }
        }
        // updateproposal
        try {
            // dispatch(updateGlobalLoaderState(true));
            const res = await updateProposal(
                {
                    ...annualDisplayedProposal,
                    customData: {
                        ...annualDisplayedProposal?.customData,
                        meta,
                    },
                },
                selectedContact.contactId || selectedContact._id
            );
            if (res?.success) {
                // set proposal to store
                dispatch(set_selected_proposal(res?.proposal));
                // dispatch(updateGlobalLoaderState(false));
            } else new Error();
        } catch (err) {
            // console.error("Update proposal error:", err);
            // dispatch(
            //     updateErrorModalState({
            //         showErrorModal: true,
            //         error: err,
            //     })
            // );
        }
    };
    useEffect(() => {
        if (Object.entries(selectedProposal).length > 0) {
            setAnnualDisplayedProposal(selectedProposal);
            const _proposal = adjustValuesIfIsMonthlyOn(selectedProposal?.customData?.isMonthlyOn, selectedProposal);
            setDisplayedProposal(_proposal);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProposal]);

    // For maintanence
    // useEffect(() => {
    // 	console.log("annual", annualDisplayedProposal);
    // }, [annualDisplayedProposal]);
    // useEffect(() => {
    // 	console.log("displayed", displayedProposal);
    // }, [displayedProposal]);

    return {
        selectedProposal,
        displayedProposal,
        annualDisplayedProposal,
        setDisplayedProposal: setBothDisplayedProposals,
        setOnlyDisplayedProposal: setDisplayedProposal,
        adjustValuesIfIsMonthlyOn,
        persistProposalToAPI,
        setAnnualDisplayedProposal,
        ...displayedProposal.customData,
    };
};
