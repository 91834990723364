import { factFindingStepsObject } from "@constants/finding-facts-constants";
import { StepEnum, StepRoute, StepTitle } from "@types";
import { useAppSelector, useSelectedProposal, useSpire } from "hooks";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { scrollToTop } from "utils/helper";
import "../styles/fact-finding.styles.scss";
import FactFindingPageContainer from "./FactFindingPageContainer";
import FactFindingSteps from "./FactFindingSteps";
import FindingFactSummaryCard from "./FindingFactSummaryCard";

const FactFinding = () => {
    const navigate = useNavigate();
    const { factFindingStep } = useParams();
    const selectedContact = useAppSelector((state) => state.selectedContact);
    const selectedProposal = useAppSelector((state) => state.selectedProposal);
    const { persistProposalToAPI } = useSelectedProposal();
    const { fetchSpireData } = useSpire();

    const handleSaveExitClicked = async () => {
        if (factFindingStep && factFindingStep !== "undefined") {
            await persistProposalToAPI(factFindingStep, {
                ...selectedProposal?.customData?.meta,
                lastAccessTime: new Date().toJSON(),
            });
            scrollToTop();
            navigate("/home");
        }
    };
    const lastCompletedStep = () => {
        const newDisplayedProposal = JSON.parse(JSON.stringify(selectedProposal));
        const lastStep: keyof StepEnum = newDisplayedProposal?.customData?.meta?.completedSteps
            ?.pop()
            ?.split(" ")
            .join();
        return factFindingStepsObject[lastStep]?.title;
    };
    const handleNextClicked = async () => {
        if (factFindingStep && factFindingStep !== "undefined") {
            const allSteps = Object.values(StepTitle);
            let updatedCompletedSteps: Array<string> = [];
            for (var i = 0; i < allSteps.length; i++) {
                updatedCompletedSteps.push(allSteps[i]);
                if (allSteps[i] === factFindingStepsObject[factFindingStep]?.title) break;
            }
            await persistProposalToAPI(factFindingStep, {
                completedSteps: updatedCompletedSteps,
                factFindingStep: factFindingStepsObject[factFindingStep]?.nextRoute,
                latestStep: lastCompletedStep(),
                lastAccessTime: new Date().toJSON(),
            });
            scrollToTop();
            // call indicativequote after Income step
            if (factFindingStepsObject[factFindingStep].routeName === StepRoute.wealth) {
                fetchSpireData();
            }
            if (factFindingStepsObject[factFindingStep].routeName === StepEnum.RiskSuitability) {
                navigate("/home/view-proposal");
            } else {
                navigate("/home/fact-finding/" + factFindingStepsObject[factFindingStep]?.nextRoute);
            }
        }
    };

    useEffect(() => {
        if (factFindingStep && !factFindingStepsObject[factFindingStep]) navigate("/home");
    }, [factFindingStep, navigate]);

    useEffect(() => {
        if (Object.values(selectedContact)?.length === 0) navigate("/advisor/landing");
        else if (Object.values(selectedProposal)?.length === 0) navigate("/home");
    }, [selectedContact, selectedProposal, navigate]);

    return (
        <main className="fact-finding-container">
            <div className="ft-split__first">
                <FactFindingSteps factFindingStep={factFindingStep} />
            </div>
            <div className="ft-split__middle">
                <FactFindingPageContainer factFindingStep={factFindingStep} />
            </div>
            <div className="ft-split__last">
                <FindingFactSummaryCard
                    factFindingStep={factFindingStep}
                    handleSaveExitClicked={handleSaveExitClicked}
                    handleNextClicked={handleNextClicked}
                />
            </div>
        </main>
    );
};

export default FactFinding;
