// import { getIndicativeQuote } from "api";
// import { updateErrorModalState } from "auth/globalSlice";
import { useAppDispatch, useAppSelector, useGoe4d, useSelectedProposal } from "hooks";
import { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { set_selected_proposal, set_view_loading } from "redux/actions";
import { getMonthlyValue } from "utils/helper";
import { hasValue } from "utils/validateInputs";

export const useSpire = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const { fetchGoe4d } = useGoe4d();
    const user = useAppSelector((state) => state.auth.user);
    const selectedContact = useAppSelector((state) => state.selectedContact);
    const goe4dTranslation = useAppSelector((state) => state.goe4dTranslation);
    const additionalPayoutAnnuity = goe4dTranslation?.body?.additionalPayoutAnnuity;

    const {
        selectedProposal,
        displayedProposal,
        annualDisplayedProposal,
        setAnnualDisplayedProposal,
        setOnlyDisplayedProposal,
        isMonthlyOn,
    } = useSelectedProposal();

    const setQuoteDetails = useCallback(() => {
        const annuityQuoteAmount =
            selectedProposal?.customData?.annuityQuoteAmount ||
            additionalPayoutAnnuity ||
            annualDisplayedProposal?.customData?.annuityQuoteAmount;

        if (
            hasValue(annuityQuoteAmount) &&
            Number(annuityQuoteAmount) !== 0 &&
            !location.pathname.includes("fact-finding")
        ) {
            return annuityQuoteAmount;
        }

        const defaultAnnuityQuoteAmount = selectedProposal?.customData?.annuityQuoteAmount || additionalPayoutAnnuity;

        if (displayedProposal?.customData && annualDisplayedProposal?.customData) {
            let updatedProposal = JSON.parse(JSON.stringify(displayedProposal));
            updatedProposal.customData.annuityQuoteAmount = isMonthlyOn
                ? getMonthlyValue(Number(defaultAnnuityQuoteAmount) || 0)
                : defaultAnnuityQuoteAmount;
            setOnlyDisplayedProposal(updatedProposal);
            let updatedAnnualProposal = JSON.parse(JSON.stringify(annualDisplayedProposal));
            updatedAnnualProposal.customData.annuityQuoteAmount = defaultAnnuityQuoteAmount;
            // dispatch(set_selected_proposal(updatedAnnualProposal));
            setAnnualDisplayedProposal(updatedAnnualProposal);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displayedProposal, selectedProposal]);

    const evaluateQuoteDetails = () => {
        const annuityQuoteAmount =
            selectedProposal?.customData?.annuityQuoteAmount ||
            additionalPayoutAnnuity ||
            annualDisplayedProposal?.customData?.annuityQuoteAmount;

        if (
            hasValue(annuityQuoteAmount) &&
            Number(annuityQuoteAmount) !== 0 &&
            !location.pathname.includes("fact-finding")
        ) {
            return annuityQuoteAmount;
        }

        const defaultAnnuityQuoteAmount = additionalPayoutAnnuity || selectedProposal?.customData?.annuityQuoteAmount;

        if (displayedProposal?.customData && annualDisplayedProposal?.customData) {
            let updatedProposal = JSON.parse(JSON.stringify(displayedProposal));
            updatedProposal.customData.annuityQuoteAmount = isMonthlyOn
                ? getMonthlyValue(Number(defaultAnnuityQuoteAmount) || 0)
                : defaultAnnuityQuoteAmount;
            setOnlyDisplayedProposal(updatedProposal);
            let updatedAnnualProposal = JSON.parse(JSON.stringify(annualDisplayedProposal));
            updatedAnnualProposal.customData.annuityQuoteAmount = defaultAnnuityQuoteAmount;
            dispatch(set_selected_proposal(updatedAnnualProposal));
        }
        return defaultAnnuityQuoteAmount;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    const spireRequestData = useCallback(
        () => ({
            adviserID: user?.userId,
            payload: {
                platformDetails: {
                    platformId: "14075",
                    platformCustomAttribute1: "",
                    platformCustomAttribute2: selectedProposal?.contactId,
                    platformCustomAttribute3: selectedProposal?.proposalId,
                },
                adviserDetails: {
                    adviserId: user?.userId,
                    firstName: user?.loggedInUsername?.split(",")[0].trim() || "",
                    lastName: user?.loggedInUsername?.split(",")[1].trim() || "",
                    emailAddress: user?.userEmail,
                    fsaRegisteredNumber: "FSA88493",
                    firmFsaRef: "808934",
                },
                clientDetails: {
                    clientId: selectedContact?.contactId || selectedContact._id,
                    title: "Mr",
                    firstName: selectedContact?.firstName,
                    lastName: selectedContact?.lastName,
                    dateOfBirth: new Date(selectedContact?.birthdate || "").toLocaleDateString(),
                    permanentAddressLine1: "Address 1",
                    permanentAddressLine2: "Address 2",
                    permanentAddressLine3: "Address 3",
                    permanentAddressLine4: "Address 4",
                    permanentAddressPostCode: selectedContact?.postcode,
                    gender: "M",
                    emailAddress: user?.userEmail,
                },
                quoteDetails: {
                    amount: evaluateQuoteDetails(),
                    quotationBasis: "Payment",
                    personaDetails: {
                        persona: "FH",
                    },
                },
            },
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [annualDisplayedProposal]
    );

    useEffect(() => {
        setQuoteDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchSpireData = async () => {
        dispatch(set_view_loading(true));
        setQuoteDetails();
        /** 
         * AEX-745 Removing spire call as per new flow
         * 
            try {
                const res = await getIndicativeQuote(spireRequestData());
                if (res?.status === 200) {
                    if (res?.data?.quoteResponse?.status === "Success") {
                        // console.log("res?.data?.quoteResponse?.status === Success")
                        dispatch(set_spire_data(res?.data || {}));
                        // fetchGoe4d()
                    } else if (res?.data?.quoteResponse?.status === "Error") {
                        // console.log("res?.data?.quoteResponse?.status === Error")
                        dispatch(set_spire_data({}));
                        // AEX-654 Remove spire error modal
                        // dispatch(
                        // 	updateErrorModalState({
                        // 		showErrorModal: true,
                        // 		error: {
                        // 			message: res?.data?.quoteResponse?.errorNote,
                        // 			name: res?.data?.quoteResponse?.status,
                        // 		},
                        // 	})
                        // );
                    }
                }
            } catch (err: any) {
                console.error(err);
                // console.log("fetchSpireData failed!!!!")
                // AEX-654 Remove spire error modal
                // dispatch(
                // 	updateErrorModalState({
                // 		showErrorModal: true,
                // 		error: err?.response?.data || {
                // 			message: err?.response?.data?.quoteResponse?.errorNote,
                // 			name: err?.response?.data?.quoteResponse?.status,
                // 		},
                // 	})
                // );
            }
            dispatch(set_view_loading(false));
        */
        if (!location.pathname.includes("fact-finding")) {
            fetchGoe4d(true); //futureAnnuityProj: true
            // fetchGoe4d(false); //futureAnnuityProj: true
        }
    };

    return {
        fetchSpireData,
        spireRequestData,
    };
};
